@font-face {
  font-family: "iconfont"; /* Project id 4644514 */
  src: url('iconfont.woff2?t=1730703890258') format('woff2'),
       url('iconfont.woff?t=1730703890258') format('woff'),
       url('iconfont.ttf?t=1730703890258') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bofang1:before {
  content: "\e611";
}

.icon-xiasanjiao:before {
  content: "\e63f";
}

.icon-diqiu:before {
  content: "\e62e";
}

.icon-xingxing:before {
  content: "\e60b";
}

.icon-email:before {
  content: "\e66f";
}

.icon-guanbi:before {
  content: "\e60c";
}

.icon-wenjianshangchuan:before {
  content: "\e61e";
}

.icon-jisuanqi:before {
  content: "\e610";
}

.icon-daibanliebiaoxiangmufuhao:before {
  content: "\e60e";
}

.icon-bofang:before {
  content: "\e60a";
}

.icon-email-s:before {
  content: "\e65e";
}

.icon-danxiangjiantou:before {
  content: "\e665";
}

.icon-mapmarker:before {
  content: "\e777";
}

.icon-instagram:before {
  content: "\e74d";
}

.icon-facebook:before {
  content: "\e650";
}

.icon-youtube:before {
  content: "\ec32";
}

.icon-big-left:before {
  content: "\e602";
}

.icon-big-right:before {
  content: "\e603";
}

.icon-middle-left:before {
  content: "\e600";
}

.icon-middle-right:before {
  content: "\e601";
}

