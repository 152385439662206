@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  @include res(min-height,5rem,(md:32.45vw,sm:37.45vw,xs:50vw));
  @include res(padding-top,1.35rem,(sm:1rem,xs:10vw));
  @include res(padding-bottom,0,(md:5vw,sm:1rem,xs:12vw));
  .bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .t{
    color: #fff;
    width: 100%;
    box-sizing: border-box;
    h1{
      font-family: 'IntegralCF-Bold';
      @include res(font-size, 2.5vw,(md:.58rem,sm:.68rem,xs:.5rem));
      @include res(margin-bottom, .05rem);
      span{
        display: inline-block;
        position: relative;
        z-index: 1;
        &::after{
          content: '';
          display: block;
          width: calc(100% + .2rem);
          background-color: $color-main;
          position: absolute;
          bottom: 5%;
          left: -.1rem;
          z-index: -1;
          @include res(height,.27rem);
        }
      }
    }
    h2{
      font-family: 'integralcf-regular';
      @include res(margin-bottom, .15rem);
    }
    .desc{
      line-height: 1.3;
      @include res(font-size, .24rem,(md:.3rem,sm:.4rem,xs:.3rem));
      @media screen and(max-width:1920px) {
        @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.3rem));
      }
    }
  }
}