div.lq-score {
    display: inline-block;
}

div.lq-score ul,
div.lq-score li {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

div.lq-score>ul>li {
    float: left;
    cursor: pointer;
    padding: 0 2px;
    transition: color 0.3s;
    transition: color 0.3s;
    -moz-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
}

div.lq-score>ul:after {
    content: "";
    display: block;
    clear: both;
}