@import "../../assets/styles/utils";

footer{
  background: url(../../assets/images/footer_bg.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  @include res(text-align,null,(xs:center));
  @include res(display,flex,(xs:block));
  @include res(padding-top,.55rem,(xs:.6rem));
  @include res(padding-bottom,.55rem,(xs:.6rem));
  @include res(padding-left,1rem,(md:.5rem,xs:.3rem));
  @include res(padding-right,1rem,(md:.5rem,xs:.3rem));
  .left{
    flex-shrink: 0;
    img{
      height: auto;
      display: block;
      margin: 0 auto;
      @include res(width,3.8rem,(md:5rem));
    }
  }
  .right{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    @include res(margin-top,0,(xs:.2rem));
    @include res(justify-content,flex-end,(xs:center));
    .nav{
      display: flex;
      align-items: center;
      font-family: 'integralcf-regular';
      @include res(font-size, .17rem,(md:.22rem));
      @include res(display,flex,(md:none));
      a{
        transition: background-color .3s;
        display: inline-block;
        border-radius: .03rem;
        @include res(padding, 0 .1rem);
        @include res(padding-top, .03rem);
        &:not(:last-child){
          @include res(margin-right,.15rem);
        }
        &:hover{
          background-color: $color-main;
        }
        &.active{
          background-color: transparent;
          color: $color-main-light;
        }
      }
    }
    .fg{
      background-color: #fff;
      @include res(display,block,(md:none));
      @include res(width,.01rem,(md:1px));
      @include res(height,.2rem,(md:1px));
      @include res(margin-right,.3rem);
      @include res(margin-left,.2rem);
    }
    .dealer{
      a{
        border: solid #574194;
        color: #fff;
        text-transform: uppercase;
        font-family: 'integralcf-regular';
        box-shadow: 0 0 .2rem rgba(72,52,129,1) inset;
        transition: background .3s;
        white-space: nowrap;
        display: block;
        @include res(border-radius,8px,(xs:5px));
        @include res(box-shadow, 0 0 .2rem rgba(72,52,129,1) inset,(xs:0 0 .4rem rgba(72,52,129,1) inset));
        @include res(font-size, .14rem,(md:.24rem,sm:.28rem,xs:.2rem));
        @include res(border-width, .01rem,(md:1px));
        @include res(padding,0 .15rem,(md:0 .2rem,sm:0 .25rem,xs:0 .1rem));
        @include res(padding-top, .06rem,(md:.08rem,sm:.1rem,xs:.06rem));
        @include res(padding-bottom, .02rem,(sm:.05rem,xs:.02rem));
        @include res(margin-right,.3rem,(md:.2rem,xs:.1rem));
        &:hover{
          background: #574194;
        }
      }
    }
    .social{
      display: flex;
      align-items: center;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        color: #000;
        transition: all .3s;
        @include res(width,.32rem,(md:.5rem,sm:.7rem,xs:.54rem));
        @include res(height,.32rem,(md:.5rem,sm:.7rem,xs:.54rem));
        &:not(:last-child){
          @include res(margin-right,.3rem,(md:.2rem,xs:.1rem));
        }
        .iconfont{
          @include res(font-size, .22rem,(md:.34rem,sm:.46rem,xs:.4rem));
        }
        &:hover{
          background: $color-main;
          color: #fff;
        }
      }
    }
  }
}