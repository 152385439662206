@import "../../assets/styles/utils";

.header-zw{
  @include res(height, 1.6rem,(md:2rem,sm:1.2rem,xs:1rem));
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: transform .3s;
  &.scroll{
    // @include res(transform,translateY(-.8rem),(md:translateY(-1rem),sm:translateY(0)));
  }
  .header-top{
    align-items: center;
    justify-content: space-between;
    background: url(../../assets/images/header_bg.jpg) no-repeat center;
    background-size: cover;
    @include res(display,flex);
    @include res(height,.8rem,(md:1rem,sm:1.2rem,xs:1rem));
    .logo{
      img{
        display: block;
        width: auto;
        max-height: 90%;
        @include res(height,.68rem,(md:.9rem,xs:.6rem));
        @include res(margin-left,-.2rem,(xs:-.4rem));
      }
    }
    .right{
      display: flex;
      align-items: center;
      .dealer{
        a{
          border: solid #574194;
          color: #fff;
          text-transform: uppercase;
          font-family: 'integralcf-regular';
          box-shadow: 0 0 .2rem rgba(72,52,129,1) inset;
          transition: background .3s;
          white-space: nowrap;
          display: block;
          @include res(border-radius,8px,(xs:5px));
          @include res(box-shadow, 0 0 .2rem rgba(72,52,129,1) inset,(xs:0 0 .4rem rgba(72,52,129,1) inset));
          @include res(font-size, .14rem,(md:.24rem,sm:.28rem,xs:.2rem));
          @include res(border-width, .01rem,(md:1px));
          @include res(padding,0 .15rem,(md:0 .2rem,sm:0 .25rem,xs:0 .1rem));
          @include res(padding-top, .06rem,(md:.08rem,sm:.1rem,xs:.06rem));
          @include res(padding-bottom, .02rem,(sm:.05rem,xs:.02rem));
          @include res(margin-right,.3rem,(md:.2rem,xs:.1rem));
          &:hover{
            background: #574194;
          }
        }
      }
      .header-social{
        display: flex;
        align-items: center;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #fff;
          color: #000;
          transition: all .3s;
          @include res(width,.32rem,(md:.5rem,sm:.7rem,xs:.54rem));
          @include res(height,.32rem,(md:.5rem,sm:.7rem,xs:.54rem));
          &:not(:last-child){
            @include res(margin-right,.3rem,(md:.2rem,xs:.1rem));
          }
          .iconfont{
            @include res(font-size, .22rem,(md:.34rem,sm:.46rem,xs:.4rem));
          }
          &:hover{
            background: $color-main;
            color: #fff;
          }
        }
      }
      .hamburger{
        @include res(display, none,(sm:block));
        @include res(margin-left, .2rem,(xs:.1rem));
        @include res(margin-right,0,(xs:-.3rem));
      }
    }
  }
  .header-nav{
    position: relative;
    background-color: #fff;
    font-family: 'integralcf-regular';
    box-shadow: 0 0 .1rem rgba(0,0,0,.1);
    @include res(display, block,(sm:none));
    @include res(height, .8rem,(md:1rem));
    ul{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        height: 100%;
        >a{
          height: 100%;
          display: flex;
          align-items: center;
          color: #000;
          transition: color .3s;
          @include res(font-size, .18rem,(md:.22rem));
          .iconfont{
            display: block;
            transform: rotate(0deg);
            transform-origin: center;
            transition: transform .3s;
            @include res(margin-top,-.05rem);
            @include res(margin-left,.05rem);
            @include res(font-size, .12rem,(md:.16rem));
          }
        }
        &:first-child{
          >a{
            position: relative;
            @include res(padding-right, .15rem,(md:.2rem));
            .icon{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              @include res(right, 0);
            }
          }
          .sub-nav{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: rgba(255,255,255,.85);
            backdrop-filter: blur(5px);
            display: none;
            @include res(height,3.3rem);
            .sub-nav-tab{
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              height: 100%;
              @include res(width,16.15%);
              @include res(height,3.3rem);
              >div{
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #fff;
                cursor: pointer;
                transition: color .3s;
                @include res(font-size,.2rem,(md:.26rem,sm:.4rem,xs:.3rem));
                @include res(padding-right,.6rem,(md:.4rem));
                &.active{
                  color: $color-yellow;
                }
              }
            }
            .swiper{
              flex-shrink: 0;
              @include res(height,3.3rem);
              @include res(width,83.85%);
              .swiper-slide{
                display: flex;
                a{
                  flex-shrink: 0;
                  transition: background .3s;
                  &:hover{
                    background: rgba(0,0,0,.1);
                  }
                  img{
                    display: block;
                    height: auto;
                    @include res(width,2.95rem)
                  }
                }
              }
            }
          }
        }
        &:hover{
          a{
            color: $color-main;
          }
        }
        &.hover{
          a{
            color: $color-main;
            .iconfont{
              transform: rotate(180deg);
            }
          }
        }
        &.active{
          a{
            color: $color-main-light;
          }
        }
      }
    }
  }
}
.mob-header{
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 998;
  height: 100vh;
  overflow-x: hidden;
  background: rgba(255,255,255,.85);
  transition: all $anime-duration;
  color: $color-main;
  backdrop-filter: blur(5px);
  @include res(padding-top, 1.8rem,(xs:1.3rem));
  @include res(padding-bottom, .5rem,(xs:.5rem));
  @include res(padding-left, 1rem,(xs:.5rem));
  @include res(padding-right, 1rem,(xs:.5rem));
  @include res(width,60%,(sm:80%,xs:100%));
  &.show{
    transform: translateX(-100%);
  }
  ul{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    li{
      border-bottom: 1px solid rgba($color-main,.1);
      letter-spacing: .01rem;
      &.active{
        .title .iconfont{
          transform: rotate(90deg);
        }
      }
      .title{
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        font-family: "integralcf-regular";
        @include res(line-height,1.4rem,(xs:1rem));
        @include res(display,flex);
        @include res(font-size,.4rem,(sm:.4rem,xs:.32rem));
        a{
          flex-grow: 1;
        }
        .iconfont{
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          transition: all $anime-duration;
          transform-origin: center;
          @include res(display,none);
          @include res(font-size,.4rem,(xs:.34rem));
          @include res(width,1.4rem,(xs:1rem));
          @include res(height,1.4rem,(xs:1rem));
        }
      }
      .sub-nav{
        display: none;
        text-transform: uppercase;
        font-weight: bold;
        @include res(padding-left, .3rem,(xs:.2rem));
        @include res(padding-right, .3rem,(xs:.2rem));
        @include res(padding-bottom,.3rem,(xs:.2rem));
        &::after{
          content: '';
          display: block;
          clear: both;
        }
        a{
          display: block;
          float: left;
          color: $color-main-light;
          opacity: .8;
          @include res(font-size,null,(xs:.3rem));
          @include res(width,50%,(xs:100%));
          @include res(margin-bottom,.2rem,(xs:0));
        }
      }
    }
    .mob-social{
      @include res(margin-top, .4rem);
      a{
        @include res(margin-left, .2rem);
        .iconfont{
          @include res(font-size, .5rem,(sm:.6rem,xs:.5rem));
        }
      }
    }
  } 
}
.menu-mask{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  position: fixed;
  top:0;
  left: 0;
  z-index: 997;
  display: none;
}
