@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'IntegralCF-Bold';
  src: url('../fonts/IntegralCF-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'integralcf-medium';
  src: url('../fonts/integralcf-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'integralcf-regular';
  src: url('../fonts/integralcf-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyCF-Medium';
  src: url('../fonts/VisbyCF-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'VisbyCF-Bold';
  src: url('../fonts/VisbyCF-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family: 'Montserrat';
//   src: url('../fonts/Montserrat-Light-6.otf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'BAHNSCHRIFT';
//   src: url('../fonts/BAHNSCHRIFT.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  -webkit-font-smoothing: antialiased;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "VisbyCF-Medium","PingFang SC","Microsoft YaHei";
  line-height: 1.5;
  position: relative;
  color: #111;
  background-color: #fff;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  // @include res(font-size,1.25vw,(md:.3rem,sm:.4rem,xs:.34rem));
  @include res(font-size, .22rem,(md:.3rem,sm:.4rem,xs:.3rem));
  @media screen and(max-width:1920px) {
    @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.3rem));
  }
}

.overhidden{
  overflow: hidden;
}

.aspect-video {
  aspect-ratio: 16/9
}

//主体内容部分宽度
.container {
  @include res(padding-left,16.5vw,(md:1rem,xs:.5rem));
  @include res(padding-right,16.5vw,(md:1rem,xs:.5rem));
  @media screen and(max-width:1920px) {
    @include res(padding-left,12.5vw,(md:1rem,xs:.5rem));
    @include res(padding-right,12.5vw,(md:1rem,xs:.5rem));
  }
}
::selection {
  background: $color-main;
  color: #fff;
}
.inline-block{
  display: inline-block;
}
.red{
  color: $color-main-light;
}
.black{
  color: #000;
}
.common-more{
  display: inline-block;
  background-color: $color-main;
  color: #fff;
  border-radius: 5px;
  transition: background-color .3s;
  @include res(padding,.12rem .3rem);
  @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.3rem));
  &:hover{
    background-color: $color-main-dark;
  }
}
